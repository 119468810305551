<!--
 * @Author: frank
 * @Description: 
 * @Date: 2021-01-18 09:19:52
 * @LastEditTime: 2021-01-31 10:34:35
 * @FilePath: /shop_frontend/src/views/memberCard/index.vue
-->
<template>
  <div class="page">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
   name: 'MemberCard',
  data() {
    return {
    };
  },
};
</script>
<style lang="less" scoped>

</style>
